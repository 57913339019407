/* CSS Resets */

*, *::before, *::after {

  box-sizing: border-box;
  
  }
  
  * {
  
  margin: 0;
  }
  
  body {
    /* background-color: black; */
    overflow-x: hidden;
  
  line-height: 1.5;
  scroll-behavior: smooth;
  
  -webkit-font-smoothing: antialiased;
  
  }
  
  img, picture, video, canvas, svg {
  
  display: block;
  
  max-width: 100%;
  
  }
  
  input, button, textarea, select {
  
  font: inherit;
  
  }
  
  p, h1, h2, h3, h4, h5, h6 {
  
  overflow-wrap: break-word;
  
  }
  
  #root, #__next {
  
  isolation: isolate;
  
  }

  html {
    -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

 }




 .loader {
  width: fit-content;
  font-weight: bold;
  white-space: pre;
  font-size: 30px;
  line-height: 1.2em;
  height: 1.2em;
  overflow: hidden;
  color:white
}
.loader:before {
  content:"Loading...\AgodnLai...\Aoiaglni...\ALiongad...\Agindola...\Analoidg...";
  white-space: pre;
  display:inline-block;
  animation: l38 1s infinite steps(6);
}

@keyframes l38 {
  100%{transform: translateY(-100%)}
}


.cls-1{
  fill: #0000;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}

  /* * { border: 1px solid rgb(233, 58, 58); } */
  
